import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { OrderSubmitted, agentFlowerNameList, getAuditedPageList, batchAudit } from '@/api/ceshi_xiugai/whole';
import DisplaySettings from '@/components/DisplaySettings/DisplaySettings';
import { getStructureListArr } from '@/api/PublicAPI';
import { getUserList as _getUserList } from '@/api/updateUserInfo/structure';
import Head from '@/components/head/index';
import auditDialog from '@/components/auditDialog/index';
import MyExportExcel from '@/components/MyExportExcel/index';
import mailListEs from '@/components/StaffSelect/mailListRadioEs';
import mailListment from '@/components/StaffSelect/mailListRadioment';
import mailListData from '@/components/StaffSelect/mailListRadioData';
import { getInfo } from '@/api/login';
import { getAuditDetail, cancelCustomerOrder } from '@/api/ceshi_xiugai/order';
import axios from 'axios';
import { getTokens } from '@/utils/auth';
export default {
  name: 'CollegeManagement',
  data: function data() {
    return {
      //系统教程
      course: this.$route.query.course,
      prohibit: false,
      tab: 0,
      orderIds: [],
      form: {},
      Successe: 'CustomerOrder/financialAuditSuccessExport',
      Cumulative: 'CustomerOrder/financialAuditSuccessCumulativeExport',
      //系统教程
      title: '已审核订单',
      views: 'OrderPassed',
      reverse: true,
      val: '',
      orderTypes: '',
      smoney: '',
      emoney: '',
      type: '',
      payPurpose: '',
      customer_create: '',
      order_create: '',
      FollowUpEvel: '',
      displaySettings: [],
      displaySettingsWhole: [],
      Studentleve_teacherid: '',
      Studentleve_teacherid2: '',
      submitTime: '',
      submitshow: false,
      screate_time: '',
      ecreate_time: '',
      feeDate: '',
      feeDateshow: false,
      spay_time: '',
      epay_time: '',
      Studentleve_teachername: [],
      orderDepartment: [],
      departmentEvel: [],
      bumenoptions: [],
      //部门
      orderDepartmentName: '',
      createDepartment: [],
      createDepartmentName: '',
      follow_page: 1,
      //分页
      follow_pagesize: 20,
      //多少条数据
      loading: false,
      creatershow: false,
      ordershow: false,
      feeshow: false,
      labelpeersshow: false,
      noteshow: false,
      showittnzy: '',
      structure_idshow: false,
      ittnzyshow: false,
      // zzy
      loadings: false,
      loadinge: false,
      typeShow: false,
      //客户种类
      modeShow: false,
      //支付方式
      submitShow: false,
      //客户创建时间
      purchaseShow: false,
      purchase: '',
      purchaseArr: [{
        id: 0,
        name: '否'
      }, {
        id: 1,
        name: '是'
      }],
      sourceoptions: [],
      source_client_id: '',
      //花名id
      client_name: '',
      labelpeers: '',
      remark: '',
      ittnzy: '',
      Reviewer: '',
      AuditTime: '',
      AuditStart_time: '',
      AuditEnd_time: '',
      // zzy
      types: '',
      //客户种类
      typeArr: [{
        label: '广告',
        value: 1
      }, {
        label: '终端',
        value: 2
      }, {
        label: '渠道',
        value: 3
      }],
      mode: '',
      //支付方式
      modeArr: [{
        label: '微信',
        value: 1
      }, {
        label: '支付宝',
        value: 2
      }, {
        label: '刷卡',
        value: 3
      }, {
        label: '现金',
        value: 4
      }, {
        label: '银行卡转账',
        value: 5
      }],
      establishTime: '',
      //客户创建时间
      estStart_time: '',
      estEnd_time: '',
      // zzy
      Intendeds: [],
      Intended: '',
      //意向专业
      flower_Array: [],
      //花名
      // 列表请求参数
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      pay_time_order: '',
      create_time_order: '',
      client_create_time: '',
      typeoptions: [{
        value: 1,
        label: '是'
      }, {
        value: 2,
        label: '否'
      }],
      feeoptions: [{
        value: 1,
        label: '报名费'
      }, {
        value: 2,
        label: '定金'
      }, {
        value: 3,
        label: '学费'
      }, {
        value: 4,
        label: '资料费'
      }, {
        value: 5,
        label: '语言培训费'
      }, {
        value: 6,
        label: '普通留学申请费'
      }],
      userId: this.$store.getters.id,
      ClassSearch: '',
      //搜索
      currentPage4: 1,
      // 列表请求参数
      ptionse: 1,
      listLoading: true,
      //加载动画
      tableData: [],
      //院校列表
      rules: {
        title: [{
          required: true,
          message: '请输入院校名称',
          trigger: 'blur'
        }]
      },
      charge_type: '',
      Export: {}
    };
  },
  filters: {
    pay_purpose: function pay_purpose(num) {
      if (num == 1) {
        return '报名费';
      } else if (num == 2) {
        return '定金';
      } else if (num == 3) {
        return '学费';
      } else if (num == 4) {
        return '资料费';
      } else if (num == 5) {
        return '语言培训费';
      } else if (num == 6) {
        return '普通留学申请费';
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    if (this.course != 1) {
      this.getList(this.query.page, this.query.pagesize);
      // 11111111
      this.callname = this.$store.getters.callname;
      this.callpass = this.$store.getters.callpass;
      var that = this;
      // 控制表格滚动条
      this.$nextTick(function () {
        var mutationObserver = new MutationObserver(function (mutations) {
          that.setHeight();
        });
        mutationObserver.observe(_this.$refs.multipleTable.bodyWrapper, {
          attributes: true,
          subtree: true
        });
      });
      this.flowerArray(); //花名
      this.getStructure();
      this.getUserList();
      this.Departmentaddress();
      getInfo().then(function (res) {
        _this.charge_type = res.data.charge_type;
      });
    } else {}
  },
  components: {
    Head: Head,
    mailListEs: mailListEs,
    mailListData: mailListData,
    mailListment: mailListment,
    MyExportExcel: MyExportExcel,
    DisplaySettings: DisplaySettings,
    auditDialog: auditDialog
  },
  methods: {
    AuditDetails: function AuditDetails(row) {
      var _this2 = this;
      getAuditDetail({
        order_id: row.id
      }).then(function (res) {
        _this2.form = res.data;
        _this2.$refs.dialog.showDialogFun();
      });
    },
    getUserList: function getUserList() {
      var _this3 = this;
      _getUserList({
        type: '1',
        get_type: '4'
      }).then(function (res) {
        res.data.map(function (item) {
          item.realname = item.name;
          item.children = item.user_arr;
        });
        _this3.departmentEvel = res.data;
      });
    },
    orderDepartmentChange: function orderDepartmentChange(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 跟进人所属部门的最后一位ID
      this.orderDepartmentName = share;
    },
    createDepartmentChange: function createDepartmentChange(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 跟进人所属部门的最后一位ID
      this.createDepartmentName = share;
    },
    handleFee: function handleFee(val) {
      var _this4 = this;
      this.$nextTick(function () {
        _this4.query.page = 1;
        _this4.getList(_this4.query.page, _this4.query.pagesize);
      });
    },
    clearFee: function clearFee() {
      var _this5 = this;
      this.$nextTick(function () {
        _this5.query.page = 1;
        _this5.getList(_this5.query.page, _this5.query.pagesize);
      });
    },
    getList: function getList(page, pagesize, filedValue, type) {
      var _this6 = this;
      this.listLoading = true;
      var data = {
        page: page,
        pagesize: pagesize,
        source_client_id: String(this.source_client_id),
        //花名id
        order_type: this.orderTypes,
        start_price: this.smoney,
        end_price: this.emoney,
        leibie: this.types,
        pay_type: this.mode,
        start_client_create_time: this.estStart_time,
        end_client_create_time: this.estEnd_time,
        order_structure_id: this.orderDepartmentName,
        //成交人所属部门
        create_structure_id: this.createDepartmentName,
        //创建人所属部门
        mobile_or_name: this.ClassSearch,
        pay_purpose: this.payPurpose,
        is_group_purchase: this.purchase,
        customer_create_ids: String(this.Studentleve_teacherid),
        order_create_id: String(this.Studentleve_teacherid2),
        start_order_create_time: this.screate_time,
        end_order_create_time: this.ecreate_time,
        start_pay_time: this.spay_time,
        end_pay_time: this.epay_time,
        pay_time_order: this.pay_time_order,
        create_time_order: this.create_time_order,
        client_create_time: this.client_create_time,
        start_audit_time: this.AuditStart_time,
        end_audit_time: this.AuditEnd_time,
        approver: this.Reviewer
      };
      if (type == 1) {
        data.field_list = filedValue;
      }
      this.Export = data;
      getAuditedPageList(data).then(function (respomse) {
        var data = respomse.data;
        data.data.map(function (item) {
          item.pay_purpose = item.pay_purpose.split(',');
        });
        _this6.tableData = data.data;
        _this6.query.total = data.total;
        _this6.listLoading = false;
        _this6.displaySettings = respomse.data.fields;
        _this6.displaySettingsWhole = [];
        for (var item in respomse.data.base_fields) {
          _this6.displaySettingsWhole.push(respomse.data.base_fields[item]);
        }
      }).catch(function () {
        _this6.tableData = [];
        _this6.listLoading = false;
      });
    },
    formatPurpose: function formatPurpose(row, column) {
      if (row.pay_purpose == 1) {
        return '报名费';
      } else if (row.pay_purpose == 2) {
        return '定金';
      } else if (row.pay_purpose == 3) {
        return '学费';
      } else if (row.pay_purpose == 4) {
        return '资料费';
      } else if (row.pay_purpose == 5) {
        return '语言培训费';
      } else if (row.pay_purpose == 6) {
        return '普通留学申请费';
      } else {
        return '-';
      }
    },
    purposePurpose: function purposePurpose(row, column) {
      if (row.leibie == 1) {
        return '广告';
      } else if (row.leibie == 2) {
        return '终端';
      } else if (row.leibie == 3) {
        return '渠道';
      }
    },
    typeName: function typeName(row, column) {
      if (row.pay_type == 1) {
        return '微信';
      } else if (row.pay_type == 2) {
        return '支付宝';
      } else if (row.pay_type == 3) {
        return '刷卡';
      } else if (row.pay_type == 4) {
        return '现金';
      } else if (row.pay_type == 5) {
        return '银行卡转账';
      }
    },
    orderType: function orderType(row, column) {
      if (row.order_type == 1) {
        return '分期';
      } else if (row.order_type == 2) {
        return '不分期';
      }
    },
    formatType: function formatType(row, column) {
      if (row.order_type == 1) {
        return '是';
      } else {
        return '否';
      }
    },
    formatPeriodization: function formatPeriodization(row, column) {
      if (row.periodization == 1) {
        return '一次性全款';
      } else if (row.periodization == 2) {
        return '分两次全款';
      } else if (row.periodization == 3) {
        return '分三次全款';
      } else if (row.periodization == 4) {
        return '分四次全款';
      } else {
        return '-';
      }
    },
    formatSet: function formatSet(row, column) {
      if (row.periodization_set == 1) {
        return '第一次付款';
      } else if (row.periodization_set == 2) {
        return '第两次付款';
      } else if (row.periodization_set == 3) {
        return '第三次付款';
      } else if (row.periodization_set == 4) {
        return '第四次付款';
      } else if (row.periodization_set == 5) {
        return '一次性付全款';
      } else {
        return '-';
      }
    },
    detailed: function detailed(Interface, name, loading) {
      var _this7 = this;
      var data = this.Export;
      if (loading == 1) {
        this.loadings = true;
      } else {
        this.loadinge = true;
      }
      this.$confirm('确认导出已通过订单数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        axios.defaults.headers.common['token'] = getTokens();
        axios.defaults.headers.common['content-type'] = 'application/octet-stream,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        axios.post(process.env.VUE_APP_BASE_API2 + '/clientserver.php/' + Interface, data, {
          responseType: 'blob'
        }).then(function (res) {
          if (loading == 1) {
            _this7.loadings = false;
          } else {
            _this7.loadinge = false;
          }
          _this7.downloadExcel(res, _this7.currentTime() + '_' + name + '.xlsx');
        });
      }).catch(function () {
        if (loading == 1) {
          _this7.loadings = false;
        } else {
          _this7.loadinge = false;
        }
      });
    },
    //下载方法
    downloadExcel: function downloadExcel(res) {
      var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '未命名.xlsx';
      var a = document.createElement('a');
      var blob = new Blob([res.data], {
        type: 'application/vnd.ms-excel'
      });
      var url = URL.createObjectURL(blob);
      a.setAttribute('href', url);
      a.setAttribute('download', fileName);
      a.click();
    },
    formatMoney: function formatMoney(row, column) {
      if (row.order_type == 1) {
        return '-';
      } else {
        return row.money;
      }
    },
    formatMoneyNow: function formatMoneyNow(row, column) {
      if (row.order_type == 1) {
        return row.money;
      } else {
        return '-';
      }
    },
    flowerArray: function flowerArray() {
      var _this8 = this;
      //花名
      agentFlowerNameList().then(function (res) {
        _this8.flower_Array = res.data;
      });
    },
    Departmentaddress: function Departmentaddress() {
      var _this9 = this;
      _getUserList({
        type: '0',
        get_type: '0'
      }).then(function (res) {
        _this9.Studentleve_teachername = res.data;
      });
    },
    // 设置滚动条相关信息1111111111
    setHeight: function setHeight() {
      var widthValue = document.getElementById('customerTable').clientWidth + 'px';
      this.tableBodyWidth = document.getElementById('customerTable').childNodes[1].childNodes[0].clientWidth + 'px';
      var div = document.getElementById('tableLeft');
      div.style.width = widthValue;
      var div2 = document.getElementById('bodyLeft');
      div2.style.width = this.tableBodyWidth;
    },
    // 控制表格滚动条1111111
    exterHandleScroll: function exterHandleScroll() {
      var table = this.$refs.multipleTable.bodyWrapper;
      table.scrollLeft = this.$refs.tableLeft.scrollLeft;
    },
    screening: function screening(item) {
      //昨天/最近七天/30天
      this.value1 = '';
      this.storageTime = item;
      this.publicScreening(this.ptionse, this.storageTime[0], this.storageTime[1]);
    },
    searchOrder: function searchOrder() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    sourceVal: function sourceVal(val) {
      //来源
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      if (item === '缴费日期') {
        this.feeDateshow = true;
      } else if (item === '客户成交人') {
        this.ordershow = true;
      } else if (item === '客户创建人') {
        this.creatershow = true;
      } else if (item === '订单提交时间') {
        this.submitshow = true;
      } else if (item === '缴费用途') {
        this.feeshow = true;
      } else if (item === '客户种类') {
        this.typeShow = true;
      } else if (item === '支付方式') {
        this.modeShow = true;
      } else if (item === '是否团购') {
        this.purchaseShow = true;
      } else if (item === '客户创建时间') {
        this.submitShow = true;
      }
    },
    //点×
    Deletesshow: function Deletesshow(item) {
      this.query.page = 1;
      if (item === '订单提交时间') {
        this.submitshow = false;
        this.screate_time = '';
        this.ecreate_time = '';
        this.submitTime = '';
      } else if (item === '缴费日期') {
        this.feeDateshow = false;
        this.spay_time = '';
        this.epay_time = '';
        this.feeDate = '';
      } else if (item === '缴费用途') {
        this.feeshow = false;
        this.payPurpose = '';
      } else if (item === '客户创建人') {
        this.creatershow = false;
        this.Studentleve_teacherid = '';
      } else if (item === '客户成交人') {
        this.ordershow = false;
        this.Studentleve_teacherid2 = '';
      } else if (item === '客户种类') {
        this.typeShow = false;
        this.types = '';
      } else if (item === '支付方式') {
        this.modeShow = false;
        this.mode = '';
      } else if (item === '是否团购') {
        this.purchaseShow = false;
        this.purchase = '';
      } else if (item === '客户创建时间') {
        this.submitShow = false;
        this.establishTime = '';
        this.estStart_time = '';
        this.estEnd_time = '';
      }
      this.getList(this.query.page, this.query.pagesize);
    },
    //排序
    sortEs: function sortEs(column, prop, order) {
      // ascending 升序 、 descending降序 、 null 不排序
      if (column.prop == 'pay_time') {
        //缴费日期
        if (column.order == 'ascending') {
          this.pay_time_order = '1';
        } else if (column.order == 'descending') {
          this.pay_time_order = '2';
        } else {
          this.pay_time_order = '';
        }
        this.create_time_order = '';
        this.client_create_time = '';
      } else if (column.prop == 'create_time') {
        //订单提交时间
        if (column.order == 'ascending') {
          this.create_time_order = '1';
        } else if (column.order == 'descending') {
          this.create_time_order = '2';
        } else {
          this.create_time_order = '';
        }
        this.pay_time_order = '';
        this.client_create_time = '';
      } else if (column.prop == 'client_create_time') {
        //客户创建时间
        if (column.order == 'ascending') {
          this.client_create_time = '1';
        } else if (column.order == 'descending') {
          this.client_create_time = '2';
        } else {
          this.client_create_time = '';
        }
        this.pay_time_order = '';
        this.create_time_order = '';
      }
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    handleFeeDate: function handleFeeDate() {
      var _this10 = this;
      //缴费日期
      this.query.page = 1;
      if (this.feeDate == null) {
        this.spay_time = '';
        this.epay_time = '';
        this.$nextTick(function () {
          _this10.getList(_this10.query.page, _this10.query.pagesize);
        });
      } else {
        this.spay_time = this.feeDate[0].toString().substring(0, 10);
        this.epay_time = this.feeDate[1].toString().substring(0, 10);
        this.$nextTick(function () {
          _this10.getList(_this10.query.page, _this10.query.pagesize);
        });
      }
    },
    submitOrderTime: function submitOrderTime(item) {
      //订单提交时间
      this.query.page = 1;
      if (this.submitTime == null) {
        this.screate_time = '';
        this.ecreate_time = '';
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.screate_time = this.submitTime[0].toString().substring(0, 10);
        this.ecreate_time = this.submitTime[1].toString().substring(0, 10);
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    establishTimeChange: function establishTimeChange(item) {
      //客户创建时间
      this.query.page = 1;
      if (this.establishTime == null) {
        this.estStart_time = '';
        this.estEnd_time = '';
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.estStart_time = this.establishTime[0].toString().substring(0, 10);
        this.estEnd_time = this.establishTime[1].toString().substring(0, 10);
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    AuditTimeChange: function AuditTimeChange() {
      // 财务审核时间
      this.query.page = 1;
      if (this.AuditTime == null) {
        this.AuditStart_time = '';
        this.AuditEnd_time = '';
      } else {
        this.AuditStart_time = this.AuditTime[0].toString().substring(0, 10);
        this.AuditEnd_time = this.AuditTime[1].toString().substring(0, 10);
      }
    },
    //意向专业
    shouwittnzyclick: function shouwittnzyclick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    customerLabel: function customerLabel(item) {
      //客户标签筛选
      this.labelpeers = item;
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    //组织部门
    structureclick: function structureclick(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      var adad = share == ',' ? '' : share;
      this.structure_idEs = adad;
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    getStructure: function getStructure() {
      var _this11 = this;
      getStructureListArr().then(function (respomse) {
        _this11.bumenoptions = respomse.data;
      });
    },
    publicScreening: function publicScreening(tab, item1, item2) {
      //公共方法
      this.query.page = 1;
      if (tab == 1) {
        this.start_sea_time = item1;
        this.end_sea_time = item2;
        this.start_follow_time = '';
        this.end_follow_time = '';
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.start_follow_time = item1;
        this.end_follow_time = item2;
        this.start_sea_time = '';
        this.end_sea_time = '';
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    handleCreater: function handleCreater() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    handleOrder: function handleOrder() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    share: function share() {
      //共享客户
      if (String(this.customer_id) == '') {
        this.$message({
          type: 'info',
          message: '请选择客户'
        });
      } else {
        this.radioEs = true;
        this.mailListVisibleEs = true;
      }
    },
    staffDepartment: function staffDepartment() {
      //员工部门
      this.radioData = true;
      this.mailListVisibleData = true;
    },
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      this.getList(this.query.page, val);
      this.query.pagesize = val;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      //当前页
      this.getList(val, this.query.pagesize);
      this.query.page = val;
    },
    getTimestamp: function getTimestamp(time) {
      //把时间日期转成时间戳
      return new Date(time).getTime() / 1000;
    },
    goTodetails: function goTodetails(row, val) {
      //客户详情
      if (row.order_type == 1) {
        var routeUrl = this.$router.resolve({
          path: '/orderDetails?Cid=' + row.cid + '&id=' + row.id + '&types=1'
        });
        window.open(routeUrl.href, '_blank');
      } else {
        var _routeUrl = this.$router.resolve({
          path: '/orderDetailsts?Cid=' + row.cid + '&id=' + row.id + '&types=1'
        });
        window.open(_routeUrl.href, '_blank');
      }
    },
    edit: function edit(row) {
      var data = {
        customer_id: row.cid,
        id: row.id,
        edit: row.id,
        customer_data_id: row.cd_id
      };
      this.$router.push({
        path: '/orderInfo',
        query: data
      });
    },
    cancel: function cancel(row) {
      var _this12 = this;
      this.$confirm('订单取消后无法恢复审核，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        cancelCustomerOrder({
          order_id: row.id
        }).then(function (res) {
          _this12.$notify({
            title: '成功',
            message: '取消成功',
            type: 'success',
            duration: 2000
          });
          _this12.query.page = 1;
          _this12.getList(_this12.query.page, _this12.query.pagesize);
        });
      });
    }
  }
};